import {
  MutateOptions,
  QueryKey,
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient
} from 'react-query'

import {
  authorizeInApple,
  checkResetPasswordCode,
  createMarketingContact,
  createPaypalAccountDetails,
  createReport,
  createShippingReturnRule,
  createTimeFrame,
  deleteTimeFrame,
  deleteUser,
  getGuestToken,
  paypalAccountLink,
  paypalPayment,
  paypalShippingPayment,
  resendResetPasswordCode,
  resendVerificationCode,
  sendMissingVehicle,
  sendResetPassword,
  setHolidayMode,
  signInFacebook,
  signInGoogle,
  signUpFacebook,
  signUpGoogle,
  stripeAccountLink,
  updateBusinessAddress,
  updateBusinessInformation,
  updateContactInformation,
  updateGuestUserPassword,
  updatePassword,
  updateReviewReply,
  updateShippingAddress,
  updateShippingReturnRule,
  userVerificationCode
} from 'app/services/Api/user'
import useAuthStore, { getAuthActions } from 'app/store/auth.store'

import { userKeys } from './user.keys'

interface MutationConfig {
  userEmailRegister: {
    key: QueryKey
  }
  userPhoneRegister: {
    key: QueryKey
  }
  userVerificationCode: {
    key: QueryKey
    request: UseMutateAsyncFunction<JWT, string, VerifyFV, unknown>
  }
  userBusinessInfo: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, BusinessInfoDTO, unknown>
  }
  userResendCode: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      UserResendCodeModel,
      string,
      ResendCodeDTO,
      unknown
    >
  }
  forgotPassword: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      UserResendCodeModel,
      string,
      ResendCodeDTO,
      unknown
    >
  }
  forgotPasswordVerify: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      SignedIdModel,
      string,
      ForgotPasswordVerifyDTO,
      unknown
    >
  }
  resetPassword: {
    key: QueryKey
    request: UseMutateAsyncFunction<JWT, string, ForgotPasswordFormFV, unknown>
  }
  googleLogin: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      JWT,
      string,
      ThirdPartyAuthenticationDTO,
      unknown
    >
  }
  facebookLogin: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      JWT,
      string,
      ThirdPartyAuthenticationDTO,
      unknown
    >
  }
  googleRegister: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      JWT,
      string,
      ThirdPartyRegistrationDTO,
      unknown
    >
  }
  facebookRegister: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      JWT,
      string,
      ThirdPartyRegistrationDTO,
      unknown
    >
  }
  updateContactInfo: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, ContactInfoDTO, unknown>
  }
  setHolidayMode: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, HolidayModeDTO, unknown>
  }
  updatePassword: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, UpdatePasswordDTO, unknown>
  }
  deleteUser: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      { message: string },
      string,
      string,
      unknown
    >
  }
  updateShippingAddress: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      void,
      string,
      UpdateShippingAddressDTO,
      unknown
    >
  }
  updateBusinessAddress: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      void,
      string,
      UpdateBusinessAddressDTO,
      unknown
    >
  }
  createShippingReturnRule: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      UserShippingReturnRules,
      string,
      ShippingReturnRulesDTO,
      unknown
    >
  }
  updateShippingReturnRule: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      UserShippingReturnRules,
      string,
      UpdateShippingReturnRulesDTO,
      unknown
    >
  }
  stripeAccountLink: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      { url: string },
      string,
      StripeLinkDTO,
      unknown
    >
  }
  paypalAccountLink: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      { url: string },
      string,
      PaypalLinkDTO,
      unknown
    >
  }
  createMarketingContact: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      void,
      string,
      CreateMarketingContactDTO,
      unknown
    >
  }
  createReport: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, CreateReportDTO, unknown>
  }
  updateReviewReply: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, ReviewReplyDTO>
  }
  authorizeInApple: {
    key: QueryKey
    request: UseMutateAsyncFunction<JWT, string, AppleAuthenticationDTO>
  }
  paypalPayment: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      PaymentPaypalResponse,
      string,
      PaymentPaypalDTO,
      unknown
    >
  }
  paypalShippingPayment: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      PaymentPaypalResponse,
      string,
      PaymentPaypalShippingDTO,
      unknown
    >
  }
  createPaypalAccountDetails: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      CreatePaypalAccountDetailsDTO,
      string,
      CreatePaypalAccountDetailsDTO,
      unknown
    >
  }
  getGuestToken: {
    key: QueryKey
    request: UseMutateAsyncFunction<JWT, string, void, unknown>
  }
  updateGuestUserPassword: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      JWT,
      string,
      UpdateGuestUserPasswordDTO,
      unknown
    >
  }
  sendMissingVehicle: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, MissingVehicleDTO, unknown>
  }
  createTimeFrame: {
    key: QueryKey
    request: UseMutateAsyncFunction<
      TimeFrameModel,
      string,
      TimeFrameDTO,
      unknown
    >
  }
  deleteTimeFrame: {
    key: QueryKey
    request: UseMutateAsyncFunction<void, string, string, unknown>
  }
}

const userMutationConfig: MutationConfig = {
  userEmailRegister: {
    key: userKeys.register()
  },
  userPhoneRegister: {
    key: userKeys.register()
  },
  userVerificationCode: {
    key: userKeys.verification(),
    request: userVerificationCode
  },
  userBusinessInfo: {
    key: userKeys.business(),
    request: updateBusinessInformation
  },
  userResendCode: {
    key: userKeys.code(),
    request: resendVerificationCode
  },
  forgotPassword: {
    key: userKeys.forgot(),
    request: sendResetPassword
  },
  forgotPasswordVerify: {
    key: userKeys.forgot(),
    request: checkResetPasswordCode
  },
  resetPassword: {
    key: userKeys.reset(),
    request: resendResetPasswordCode
  },
  googleLogin: {
    key: userKeys.googleLogin(),
    request: signInGoogle
  },
  facebookLogin: {
    key: userKeys.facebookLogin(),
    request: signInFacebook
  },
  googleRegister: {
    key: userKeys.googleRegister(),
    request: signUpGoogle
  },
  facebookRegister: {
    key: userKeys.facebookRegister(),
    request: signUpFacebook
  },
  updateContactInfo: {
    key: userKeys.updateContactInfo(),
    request: updateContactInformation
  },
  setHolidayMode: {
    key: userKeys.setHolidayMode(),
    request: setHolidayMode
  },
  updatePassword: {
    key: userKeys.updatePassword(),
    request: updatePassword
  },
  deleteUser: {
    key: userKeys.deleteUser(),
    request: deleteUser
  },
  updateShippingAddress: {
    key: userKeys.updateShippingAddress(),
    request: updateShippingAddress
  },
  updateBusinessAddress: {
    key: userKeys.updateBusinessAddress(),
    request: updateBusinessAddress
  },
  createShippingReturnRule: {
    key: userKeys.createShippingReturnRule(),
    request: createShippingReturnRule
  },
  updateShippingReturnRule: {
    key: userKeys.updateShippingReturnRule(),
    request: async ({ id, data }: UpdateShippingReturnRulesDTO) => {
      const resp = await updateShippingReturnRule(id, data)

      return resp
    }
  },
  stripeAccountLink: {
    key: userKeys.stripeAccountLink(),
    request: stripeAccountLink
  },
  paypalAccountLink: {
    key: userKeys.paypalAccountLink(),
    request: paypalAccountLink
  },
  createMarketingContact: {
    key: userKeys.createMarketingContact(),
    request: createMarketingContact
  },
  createReport: {
    key: userKeys.createReport(),
    request: createReport
  },
  updateReviewReply: {
    key: userKeys.updateReviewReply(),
    request: updateReviewReply
  },
  authorizeInApple: {
    key: userKeys.authorizeInApple(),
    request: authorizeInApple
  },
  paypalPayment: {
    key: userKeys.paypalPayment(),
    request: paypalPayment
  },
  paypalShippingPayment: {
    key: userKeys.paypalShippingPayment(),
    request: paypalShippingPayment
  },
  createPaypalAccountDetails: {
    key: userKeys.createPaypalAccountDetails(),
    request: createPaypalAccountDetails
  },
  getGuestToken: {
    key: userKeys.getGuestToken(),
    request: getGuestToken
  },
  updateGuestUserPassword: {
    key: userKeys.updateGuestUserPassword(),
    request: updateGuestUserPassword
  },
  sendMissingVehicle: {
    key: userKeys.sendMissingVehicle(),
    request: sendMissingVehicle
  },
  createTimeFrame: {
    key: userKeys.createTimeFrame(),
    request: createTimeFrame
  },
  deleteTimeFrame: {
    key: userKeys.deleteTimeFrame(),
    request: deleteTimeFrame
  }
}

export const useUserEmailRegisterMutation = () => {
  const actions = useAuthStore(getAuthActions)
  const { userEmailRegister: config } = userMutationConfig

  const state = useMutation(config.key, (data: SignUpDTO) =>
    actions.register(data, 'email')
  )

  return state
}

export const useUserPhoneRegisterMutation = () => {
  const actions = useAuthStore(getAuthActions)
  const { userPhoneRegister: config } = userMutationConfig

  const state = useMutation(config.key, (data: SignUpDTO) =>
    actions.register(data, 'phone')
  )

  return state
}

export const useUserVerificationCodeMutation = () => {
  const { userVerificationCode: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useUserBusinessInfoMutation = () => {
  const queryClient = useQueryClient()

  const { userBusinessInfo: config } = userMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.user)
    }
  })

  return state
}

export const useUserResendCodeMutation = () => {
  const { userResendCode: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useForgotPasswordMutation = () => {
  const { forgotPassword: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useForgotPasswordVerifyMutation = () => {
  const { forgotPasswordVerify: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useResetPasswordMutation = () => {
  const { resetPassword: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useGoogleLoginMutation = () => {
  const { googleLogin: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useGoogleRegisterMutation = () => {
  const { googleRegister: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useFacebookLoginMutation = () => {
  const { facebookLogin: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useFacebookRegisterMutation = () => {
  const { facebookRegister: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useUpdateContactInformationMutation = () => {
  const { updateContactInfo: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useSetHolidayModeMutation = () => {
  const queryClient = useQueryClient()

  const { setHolidayMode: config } = userMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.user)
    },
    onError: () => {
      queryClient.invalidateQueries(userKeys.user)
    }
  })

  return state
}

export const useUpdatePasswordMutation = () => {
  const { updatePassword: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useDeleteUserMutation = () => {
  const { deleteUser: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useUpdateShippingAddressMutation = () => {
  const queryClient = useQueryClient()

  const { updateShippingAddress: config } = userMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.user)
    }
  })

  return state
}

export const useUpdateBusinessAddressMutation = () => {
  const queryClient = useQueryClient()

  const { updateBusinessAddress: config } = userMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.user)
    }
  })

  return state
}

export const useCreateShippingReturnRuleMutation = () => {
  const queryClient = useQueryClient()
  const { createShippingReturnRule: config } = userMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.getShippingReturnRules())
      queryClient.invalidateQueries(userKeys.user)
    }
  })

  return state
}

export const useUpdateShippingReturnRuleMutation = () => {
  const queryClient = useQueryClient()
  const { updateShippingReturnRule: config } = userMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.getShippingReturnRules())
      queryClient.invalidateQueries(userKeys.user)
    }
  })

  return state
}

export const useStripeAccountLink = () => {
  const { stripeAccountLink: config } = userMutationConfig
  const state = useMutation(config.key, config.request)

  return state
}

export const usePaypalAccountLink = () => {
  const { paypalAccountLink: config } = userMutationConfig
  const state = useMutation(config.key, config.request)

  return state
}

export const useCreateMarketingContactMutation = () => {
  const { createMarketingContact: config } = userMutationConfig
  const state = useMutation(config.key, config.request)

  return state
}

export const useCreateReportMutation = () => {
  const { createReport: config } = userMutationConfig
  const state = useMutation(config.key, config.request)

  return state
}

export const useUpdateReviewReplyMutation = () => {
  const queryClient = useQueryClient()
  const { updateReviewReply: config } = userMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.getUserReviews({}))
    }
  })
  return state
}

export const usePaypalPaymentMutation = (
  options?: MutateOptions<PaymentPaypalResponse, unknown, PaymentPaypalDTO>
) => {
  const { paypalPayment: config } = userMutationConfig
  const state = useMutation(config.key, config.request, { ...options })

  return state
}

export const useAppleAuthorizeMutation = () => {
  const { authorizeInApple: config } = userMutationConfig
  const state = useMutation(config.key, config.request)

  return state
}

export const usePaypalShippingPaymentMutation = () => {
  const { paypalShippingPayment: config } = userMutationConfig
  const state = useMutation(config.key, config.request)

  return state
}

export const useCreatePaypalAccountDetailsMutation = () => {
  const { createPaypalAccountDetails: config } = userMutationConfig
  const state = useMutation(config.key, config.request)

  return state
}

export const useGetGuestTokenMutation = () => {
  const { getGuestToken: config } = userMutationConfig
  const state = useMutation(config.key, config.request)

  return state
}

export const useUpdateGuestUserPasswordMutation = () => {
  const { updateGuestUserPassword: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useSendMissingVehicleMutation = () => {
  const { sendMissingVehicle: config } = userMutationConfig

  const state = useMutation(config.key, config.request)

  return state
}

export const useCreateTimeFrameMutation = () => {
  const queryClient = useQueryClient()
  const { createTimeFrame: config } = userMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => queryClient.invalidateQueries(userKeys.getTimeFrames())
  })

  return state
}

export const useDeleteTimeFrameMutation = () => {
  const queryClient = useQueryClient()
  const { deleteTimeFrame: config } = userMutationConfig

  const state = useMutation(config.key, config.request, {
    onSuccess: () => queryClient.invalidateQueries(userKeys.getTimeFrames())
  })

  return state
}
